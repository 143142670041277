import React, { ReactElement, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  cpqSelector,
  lastModifiedDateFromNowSelector,
} from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";

interface Props {
  cpq: boolean;
  dateFromNow: string;
}

const LastModified = React.memo(
  (props: Props): ReactElement<ReactNode> => (
    <div>
      {props.cpq && <span className={"cpq"}>CPQ </span>}
      <span className={"saved-at"}>
        {props.cpq && " - "}
        <FormattedMessage id="saved at" defaultMessage="saved at" />
        {` ${props.dateFromNow}`}
      </span>
    </div>
  )
);

const mapStateToProps = (state: IStore): Props => ({
  cpq: cpqSelector(state),
  dateFromNow: lastModifiedDateFromNowSelector(state),
});

export default connect(mapStateToProps)(LastModified);
