import { get, isEmpty } from "lodash";
import moment from "moment";
import { createSelector } from "reselect";
import { Permission } from "../../../generated/axios";
import { IStore } from "../types";
import { ItemResultExt } from "./types";

const results = (state: IStore) =>
  get(state, "item.results.results") as ItemResultExt[] | undefined;

// TODO: refactor
// Abbiamo bisogno di due projectIdSelector, una con default value null e l'altra con undefined?
export const projectIdSelector = (state: IStore) =>
  get(state, "item.project.id", null) as number | null;

export const itemSelector = (state: IStore) => state.item;

export const itemIdSelector = (state: IStore) => state.item.id;

export const cpqSelector = (state: IStore) => Boolean(state.item.cpq);

export const itemCalculatingSelector = (state: IStore) =>
  state.item.calculating;

export const hasItemResults = createSelector(
  results,
  (results) => !isEmpty(results)
);

export const lastModifiedDateFromNowSelector = createSelector(
  (state: IStore) => state.item.lastModifiedDate,
  (value) => moment(value).fromNow()
);

export const dutyModeSelector = (state: IStore) =>
  state.item.dutyMode as string;

export const thermalDataChangedSelector = (state: IStore) =>
  state.item.results?.thermalDataChanged;

export const hasReadPermission = (state: IStore) =>
  state.item.permission === Permission.READ;

export const hasWritePermission = (state: IStore) =>
  state.item.permission === Permission.WRITE;

export const canEditItemSelector = (state: IStore) =>
  state.item.permission === Permission.WRITE;

export const itemResultsSelector = (state: IStore) => state.item.results;

export const itemPortfolioIdSelector = (state: IStore) =>
  state.item.portfolioId;

export const itemResultsPriceListSelector = (state: IStore) =>
  state.item.results?.priceList;

export const exportingCSVSelector = (state: IStore) =>
  Boolean(state.item.exportingCSV);
